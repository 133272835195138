<template>
    <MainContainer>
        <div class="home w-50 mx-auto text-center">
            <font-awesome-icon
                class="danger-icon"
                icon="exclamation-triangle"
            />
            <h2>Sie sind momentan nicht berechtigt diese Seite aufzurufen</h2>
            <p>
                Momentan ist der Zugriff auf diese Seite für Ihren
                Benutzeraccount gesperrt. Bitte wenden Sie sich an den
                Administrator um Zugang zu dieser Funktion zu erhalten
            </p>
        </div>
    </MainContainer>
</template>

<script>
// @ is an alias to /src
import MainContainer from "@/components/MainContainer/MainContainer";

export default {
    name: "Error403",
    components: {
        MainContainer
    }
};
</script>
